import React from 'react';
import PropTypes from 'prop-types';
import checkedImg from '../images/checked.svg';
import uncheckedImg from '../images/unchecked.svg';
import crossImg from '../images/cross.svg';
import classNames from 'classnames';
import './todoItems.css';
class TodoItem extends React.Component{
    constructor(props){
        super(props);
        this.handleClickDone = this.handleClickDone.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
    }
    /** Event change status todo */
    handleClickDone(){
        let {item,doneTodo} = this.props;
        doneTodo(item);
    }

    /** Event remove todo */
    handleRemove(){
        console.log('click');
        let {item, removeTodo} = this.props;
        removeTodo(item); 
    }

    render() {
        let { item } = this.props,
        isDone = item.isDone,
        check = (isDone) ? checkedImg : uncheckedImg,
        classItem = classNames('todoItems', {
            todoDone: isDone
        });
        return (
            <div className={classItem}>
                <img 
                    src={check} 
                    className="left" 
                    alt="icons" 
                    onClick={this.handleClickDone}
                />
                <p>{item.title}</p>
                <img 
                    src={crossImg} 
                    className="right" 
                    alt="icons"
                    onClick={this.handleRemove}
                />
            </div>
        );
    }
}

TodoItem.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        isDone: PropTypes.bool.isRequired
    }),
};
export default TodoItem;