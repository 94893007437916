import React from 'react';
import './Header.css';
const Headers = ({addItem}) => {
    let input = null;
    /** Event submit  */
    const handleSubmit = e => {
        e.preventDefault();
        let value = input.value.trim();
        if (!value) {
            return;
        }
        let item = {
            title: value,
            isDone: false
        };
        addItem(item);
        input.value = '';
    };
    return(
        <header className="header">
            <form onSubmit={handleSubmit}>
            <input ref={ref => { input = ref }} placeholder="Input text here" type="text" autoComplete="off" />
            </form>
        </header>
    );
};

export default Headers;