import React from 'react';
import './App.css';
import Headers from './components/layouts/Headers';
import Footer from './components/layouts/Footer';
import TodoItem from './components/TodoItem';
class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      todoItems: [],
      view: 'all'
    };
  }
  componentDidMount() {
    if (!localStorage.hasOwnProperty('todoItems')) {
        localStorage.setItem('todoItems', []);
    }
    let todoItems = localStorage.todoItems;
    todoItems = todoItems ? todoItems : '[]';
    todoItems = JSON.parse(todoItems);
    this.setState({
      todoItems
    });
  }

  /**
   * add item todo to todoItem
   * @param object item {title: 'title', isDone: false}
   */
  addItem = item =>{
    let {todoItems} = this.state;
      item.id = todoItems.length + 1;
      todoItems.push(item);
      this.setState({todoItems});
      todoItems = JSON.stringify(todoItems);
      localStorage.setItem('todoItems', todoItems);
  }
  /**
   * change status todo
   * @param object title {id: 1,title: 'title', isDone: false}
   * @return mix
   */
  doneTodo = item =>{
    let {todoItems} = this.state;
    todoItems.map(value => value.id === item.id ? value.isDone = !value.isDone : value);
    return this.updateList(todoItems);
  };

  /**
   * remove todo
   * @param object title {id: 1,title: 'title', isDone: false}
   * @return mix
   */
  removeTodo = item =>{
    let {todoItems} = this.state;
    todoItems.map((value, key) => value.id === item.id ? todoItems.splice(key, 1) : value);
    this.updateList(todoItems);
  };

  /**
   * Update state todoItems & localStorage
   * @param array todoItems
   * @return mix
   */
  updateList = todoItems =>{
    this.setState({todoItems});
    todoItems = JSON.stringify(todoItems);
    localStorage.setItem('todoItems', todoItems);
  };

  fillterItem(){
    let {todoItems, view} = this.state;
    todoItems = todoItems.filter((item, key)=>{
      switch(view){
        case 'done':
          return item.isDone === true;
        case 'active':
          return !item.isDone;
        default:
          return item;
      }
    });
    return todoItems;
  }

  filterUpdate = view =>{
    this.setState({view});
  };

  //Clean complete
  cleanComplete = _ => {
    let {todoItems} = this.state;
    todoItems = todoItems.filter((item, key)=> !item.isDone ? item : null);
    this.updateList(todoItems);
  };

  render(){
    let todoItems = this.fillterItem();
    return (
      <div className="App">
        <h1>Todo</h1>
        <div className="application">
          <Headers addItem={this.addItem} />
            <section className="main">
              {
                todoItems.length <=0 &&
                <p>Not found</p>
              }
              {
                todoItems.length > 0 &&
                  todoItems.map((item, key) => (
                    <TodoItem key={key} 
                      item={item}
                      doneTodo={this.doneTodo}
                      removeTodo={this.removeTodo}
                    />)
                  )
              }
            </section>
          <Footer 
            view={this.state.view}
            total={todoItems.length}
            filterUpdate={this.filterUpdate}
            cleanComplete={this.cleanComplete}/>
        </div>
      </div>
    );
  }
}

export default App;
