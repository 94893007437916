import React from 'react';
import './Footer.css';
import classNames from 'classnames';
const Footer = (props) =>{
    const handleChange = type =>{
        props.filterUpdate(type);
    };

    const handleViewAll = _ =>{
        handleChange('all');
    };
    const handleViewDone = _ =>{
        handleChange('done');
    };
    const handleViewActive = _ =>{
        handleChange('active');
    };

    //Clearn all complete
    const handleClean = _ =>{
        props.cleanComplete();
    };
    return (
        <footer className="footer">
            <div className="todo-count">
                <strong data-reactid=".0.2.0.0">{props.total}</strong>
                <span data-reactid=".0.2.0.1"> items left</span>
            </div>
            <div className="center">
                <span 
                    className={classNames('fillter', {
                        active: props.view === 'all'
                    })}
                    onClick={handleViewAll}
                >All</span>
                <span 
                    className={classNames('fillter', {
                        active: props.view === 'active'
                    })}
                    onClick={handleViewActive}
                >Active</span>
                <span 
                    className={classNames('fillter', {
                        active: props.view === 'done'
                    })}
                    onClick={handleViewDone}
                >Completed</span>
            </div>
            <div className="right">
                <button onClick={handleClean}>Clean complete</button>
            </div>
        </footer>  
    );
};
export default Footer;